import { useDispatch, useSelector } from 'react-redux'
import centerSantaIcon from 'assets/images/xmas/center-santa.svg'
import { setPayAsYouGoCreditsModalActive } from 'features/payments'

import { SubscriptionUpgradeBadge } from 'components/atoms'
import { PositionedIconWrapper } from 'components/atoms/PositionedIconWrapper'
import { RowWithAlignment } from 'components/atoms/Row'
import { isChristmas } from 'utils'

import { getSubscriptionStore } from '../store'

export const PlanQuotaBadgesRow = () => {
  const dispatch = useDispatch()

  const { fixedQuotas, payAsYouGoQuotas } = useSelector(getSubscriptionStore)

  return (
    <RowWithAlignment className='space-x-3' justifyAlignment='end'>
      {/* <PoliceCheckLayout>
        <PositionedIconWrapper
          showIcon={isChristmas()}
          position='left'
          icon={<img src={leftSantaIcon} alt='Left Icon' />}>
          <PlanCheckBadge
            id='right-to-work-check-credits'
            icon='ri-suitcase-line'
            tooltip='Right To Work Check Credits'
            quotaItem={payAsYouGoQuotas.RightToWorkCheck}
          />
        </PositionedIconWrapper>

        <PositionedIconWrapper
          showIcon={isChristmas()}
          position='left'
          icon={<img src={leftSantaIcon} alt='Left Icon' />}>
          <PlanCheckBadge
            id='police-check-credits'
            icon='ri-police-car-line'
            tooltip='Police Check Credits'
            quotaItem={payAsYouGoQuotas.PoliceCheck}
          />{' '}
        </PositionedIconWrapper>

        <PositionedIconWrapper
          showIcon={isChristmas()}
          position='left'
          icon={<img src={leftSantaIcon} alt='Left Icon' />}>
          <PlanCheckBadge
            id='volunteer-check-credits'
            icon='ri-hand-heart-line'
            tooltip='Volunteer Check Credits'
            quotaItem={payAsYouGoQuotas.VolunteerCheck}
          />
        </PositionedIconWrapper>
      </PoliceCheckLayout> */}

      {/* {isAssessmentEnabled && (
        <PositionedIconWrapper
          showIcon={isChristmas()}
          position='left'
          icon={<img src={leftSantaIcon} alt='Left Icon' />}>
          <PlanCheckBadge
            id='Assessment-credits'
            icon='ri-file-list-2-line'
            tooltip='Assessment Credits'
            quotaItem={payAsYouGoQuotas.AssessmentCredit}
          />
        </PositionedIconWrapper>
      )} */}

      <PositionedIconWrapper
        showIcon={isChristmas()}
        position='center'
        icon={<img src={centerSantaIcon} alt='Center Icon' />}>
        <button
          className='focus:outline-none'
          onClick={() => {
            dispatch(setPayAsYouGoCreditsModalActive(true))
          }}>
          <SubscriptionUpgradeBadge
            totalQuota={
              fixedQuotas.referenceCheck.purchasedQuota +
              payAsYouGoQuotas.ReferenceCheck.purchasedQuota
            }
            remainingQuota={
              fixedQuotas.referenceCheck.remainingQuota +
              payAsYouGoQuotas.ReferenceCheck.remainingQuota
            }
            isActionBtnRequired={false}
          />
        </button>
      </PositionedIconWrapper>
    </RowWithAlignment>
  )
}
