import { FC, useRef } from 'react'
import { InputGroupProps } from '@blueprintjs/core'
import { DateRange } from '@blueprintjs/datetime'
import { DateRangeInput2 } from '@blueprintjs/datetime2'
import CN from 'classnames'
import { format, parse, sub } from 'date-fns'
import moment from 'moment'

import './CalendarRangePicker.scss'

export interface CalendarRangePickerProps {
  defaultValue?: DateRange
  className?: string
  [x: string]: any
  onChange: (selectedRange: DateRange) => void
  startInputProps?: InputGroupProps
  endInputProps?: InputGroupProps
}

export const CalendarIcon = () => {
  return (
    <div className='flex flex-col h-full items-center justify-center pr-2'>
      <i className='ri-calendar-event-fill text-Gray-500 text-[18px]'></i>
    </div>
  )
}

export const CalendarRangePicker: FC<CalendarRangePickerProps> = ({
  className,
  onChange,
  defaultValue,
  startInputProps,
  endInputProps,
}: CalendarRangePickerProps) => {
  const CalendarRangePickerClasses = CN(`calendar-picker`, className, {})

  const popover = useRef<any>(null)

  return (
    <div className={CalendarRangePickerClasses}>
      <DateRangeInput2
        popoverProps={{
          popoverRef: popover,
        }}
        allowSingleDayRange={true}
        closeOnSelection={false}
        contiguousCalendarMonths={false}
        startInputProps={{
          placeholder: 'Start Date',
          rightElement: <CalendarIcon />,
          className: 'w-[150px]',
          ...startInputProps,
        }}
        endInputProps={{
          placeholder: 'End Date',
          rightElement: <CalendarIcon />,
          className: 'w-[150px]',
          ...endInputProps,
        }}
        defaultValue={defaultValue}
        formatDate={(date) => format(date, 'dd/MM/yyyy')}
        parseDate={(str) => parse(str, 'dd/MM/yyyy', new Date())}
        onChange={(selectedRange: any) => {
          onChange(selectedRange)
        }}
        selectAllOnFocus={true}
        maxDate={moment().add(1, 'days').toDate()}
        shortcuts={[
          {
            label: 'Last Month',
            includeTime: false,
            dateRange: [
              moment().subtract(1, 'month').startOf('month').toDate(),
              moment().subtract(1, 'month').endOf('month').toDate(),
            ],
          },
          {
            label: 'Last 06 Month',
            includeTime: false,
            dateRange: [
              moment().subtract(6, 'month').startOf('month').toDate(),
              moment().subtract(1, 'month').endOf('month').toDate(),
            ],
          },
          {
            label: 'Last Year',
            includeTime: false,
            dateRange: [
              moment().subtract(1, 'year').startOf('year').toDate(),
              moment().subtract(1, 'year').endOf('year').toDate(),
            ],
          },
        ]}
      />
    </div>
  )
}

CalendarRangePicker.defaultProps = {
  defaultValue: [new Date(), new Date()],
  highlightCurrentDay: true,
  singleMonthOnly: true,
  contiguousCalendarMonths: false,
  shortcuts: false,
  minDate: sub(new Date(), {
    years: 10,
  }),
  maxDate: new Date(),
  formatDate: (date) => format(date, 'dd/mm/yyyy'),
  parseDate: (str) => parse(str, 'dd/mm/yyyy', new Date()),
}

export default CalendarRangePicker
